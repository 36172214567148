<template>
  <router-view v-on:logged="setLogged($event.value)" />
</template>

<script>
export default {
  element: "#app",
  methods: {
    setLogged(value) {
      localStorage.setItem("Logged", value);
    },
  },
};
</script>
