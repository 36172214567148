import axiosInstance from "./axiosInstance";
import $config from "./config";
import { forceLogout } from "@/services/APIcalls.js";

const accessTokenKey = $config.get("tokenKey");
const refreshTokenKey = $config.get("refreshKey");

export default function setup() {
  axiosInstance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem(accessTokenKey);

    if (accessToken && !config.headers["X-Refresh-Request"]) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  });

  let refreshPromise = null;

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log("ERROR RESPONSE", error);

      if (
        error.config.headers["X-Refresh-Request"] ||
        error.response.status !== 401
      ) {
        return Promise.reject(error);
      }

      if (!refreshPromise) {
        refreshPromise = new Promise((resolve, reject) => {
          const url = "/token";
          const refreshToken = localStorage.getItem(refreshTokenKey);

          const request = {
            grant_type: "refresh_token",
            client_id: $config.get("client_id"),
            client_secret: $config.get("client_secret"),
            refresh_token: refreshToken,
          };

          axiosInstance
            .post(url, request, {
              headers: {
                "X-Refresh-Request": true,
              },
            })
            .then((res) => {
              localStorage.setItem(accessTokenKey, res.data.access_token);
              localStorage.setItem(refreshTokenKey, res.data.refresh_token);

              resolve(res);
            })
            .catch((error) => {
              const tokenError = new Error("Cannot refresh token");
              tokenError.originalError = error;
              forceLogout();
              reject(error);
            })
            .finally(() => {
              refreshPromise = null;
            });
        });
      }

      return refreshPromise.then(() => axiosInstance(error.config));
    }
  );
}
