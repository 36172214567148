import axiosInstance from "./axiosInstance";
import $config from "./config";
import md5 from 'md5';

const accessTokenKey = $config.get("tokenKey");
const refreshTokenKey = $config.get("refreshKey");
  
export const getMe = async () => {
  return await axiosInstance
    .get("/me")
    .then((res) => {
      if (!res.data.id.length) localStorage.removeItem("me");
      else localStorage.setItem("me", JSON.stringify(res.data));
    })
    .catch(() => localStorage.removeItem("me"));
};

export const forceLogout = () => {
  const me = JSON.parse(localStorage.getItem("me"));
  localStorage.setItem('lmd5', md5(me.email.toLowerCase()));
  const completed = [
    localStorage.removeItem(accessTokenKey),
    localStorage.removeItem(refreshTokenKey),
    localStorage.removeItem("me"),
  ];
  Promise.allSettled(completed).then(() => window.location.reload(true));
};

export const login = async (username, password) => {
  const url = "token";
  const emailMd5 = localStorage.getItem("lmd5") === md5(username.toLowerCase()) ? null : localStorage.getItem("lmd5");
  const request = {
    grant_type: "password",
    username: username,
    password: password,
    client_id: $config.get("client_id"),
    lmd5: emailMd5
  };
  return axiosInstance.post(url, request).then((res) => {
    localStorage.setItem(accessTokenKey, res.data.access_token);
    localStorage.setItem(refreshTokenKey, res.data.refresh_token);
  });
};

export const swipeBadge = async (data) => {
  const url = "logs";
  return axiosInstance.post(url, data);
};

export const listVacationRequest = async (filters) => {
  const url = "/vacationRequests";
  return axiosInstance.get(url, { params: filters });
};

export const askOvertime = async (data) => {
  const url = "/overtimeRequests";
  return axiosInstance.post(url, data);
};

export const askVacation = async (data) => {
  const url = "/vacationRequests";
  return axiosInstance.post(url, data);
};

export const renounceVacation = async (id) => {
  const url = `/vacationRequests/${id}`
  return axiosInstance.patch(url);
};

export const listLogs = async (page, day) => {
  const stringDay = day ? `&t0=${day}&t1=${day}` : '';
  return await axiosInstance
    .get(`/logs?sortDesc=true&page=${page}${stringDay}`)
    .then((res) => {return(res)})
    .catch((err) => console.log(err));
};

export const editLog = async (payload,id) => {
  return await axiosInstance
    .put(`/logs/${id}`, payload)
};

export const changePasswordTokens = async (emailMd5) => {
  return axiosInstance
    .post(`/users/${emailMd5}/changePasswordTokens`)
};

export const setPasswordWithTokens = async (password, token, emailMd5) => {
  return axiosInstance
    .post(`/users/${emailMd5}/password`, {password: password, token: token})
};

export const changePassword = async (passwordData) => {
  const url = "change-password";
  return axiosInstance.post(url, passwordData);
};

export const listMontlyHoursWorked = async (filters) => {
  return axiosInstance
  .get("/hours-worked-montly",{ params: filters })
  .then((res) => {return(res)})
  .catch((err) => console.log(err));
}

export const createNewTimeLog = async(data) => {
  return axiosInstance
  .post(`/new-log`, data)
}

export const fetchNonCompilance = async (id) => {
  return axiosInstance
  .get(`/non-compliances/${id}`)
}