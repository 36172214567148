import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Public/Login.vue"),
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/password-recovery",
    name: "password-recovery",
    component: () => import("@/views/Public/PasswordRecovery.vue"),
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/password-reset/:emailMd5/change-password/:token",
    name: "set-password-recovery",
    component: () => import("@/views/Public/ChooseAPassword.vue"),
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Private/MainPage.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  const logged = Boolean(localStorage.getItem("me"));
  if (!to.meta.anonymous && !logged) next({ name: "login" });
  else if (to.meta.anonymous && logged) next({ name: "home" });
  else next();
});
export default router
