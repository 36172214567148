export default {
  //pippo: (function () { console.log('url: ',process.env.BASE_URL); return 2; })(),
  //apiBaseUrl: 'https://api.timbri.botti-group.it/api/v1/',
  //apiBaseUrl: 'https://admin.timetrack.local/api/v1/',
  apiBaseUrl: `${process.env.VUE_APP_API_BASE_URL || "/api/v1"}`,
  STORAGE_KEY: "app",
  tokenKey: "employee-app-token",
  refreshKey: "employee-app-refresh",
  client_id: `${process.env.VUE_APP_OAUTH2_CLIENT_ID || "mts"}`,
  client_secret: `${process.env.VUE_APP_OAUTH2_CLIENT_SECRET || ""}`,
  env: `${process.env.NODE_ENV} || 'dev'`,
  enableRequestVacation: true,
  enableRequestOvertime: true,
  employeeCanCreateTimeLogs: true,
};