import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { Icon }  from 'leaflet';
import interceptorsSetup from "./services/interceptors";
import "./theme/custom_theme.scss";
import 'leaflet/dist/leaflet.css';
import VueGeolocation from 'vue-browser-geolocation';
import VCalendar from 'v-calendar';

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

interceptorsSetup();
Vue.config.productionTip = false
Vue.use(VueGeolocation);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VCalendar, {
  locale: 'it',
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
